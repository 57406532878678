/* Kausalyze Brand Styling */
:root {
  /* Primary Colors */
  --primary-color: #016565;
  --primary-light: #029797;
  --primary-dark: #015151;

  /* Secondary Colors */
  --secondary-color: #c12f2f;
  --secondary-light: #d44f4f;
  --secondary-dark: #982525;

  /* Neutral Colors */
  --white: #ffffff;
  --light-gray: #f8f9fa;
  --medium-gray: #e9ecef;
  --dark-gray: #343a40;
  --black: #212529;

  /* Text Colors */
  --text-light: #f8f9fa;
  --text-dark: #343a40;
  --text-muted: #6c757d;

  /* Functional Colors */
  --success: #029797;
  --warning: #ffc107;
  --danger: #c12f2f;
  --info: #016565;

  /* Gradients */
  --gradient-primary: linear-gradient(to right, var(--primary-color), var(--primary-light));
  --gradient-secondary: linear-gradient(to right, var(--secondary-color), var(--secondary-light));
  --gradient-accent: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.15);

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 20px;
  --border-radius-circle: 50%;

  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;
  --font-size-xxxl: 3rem;

  /* Font Weights */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Animation */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* Scroll Behavior */
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

/* Global Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: var(--text-dark);
  background-color: var(--white);
  overflow-x: hidden;
  scroll-padding-top: 80px;
  /* Account for fixed header */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--text-dark);
}

h1 {
  font-size: var(--font-size-xxxl);
}

h2 {
  font-size: var(--font-size-xxl);
}

h3 {
  font-size: var(--font-size-xl);
}

h4 {
  font-size: var(--font-size-lg);
}

h5 {
  font-size: var(--font-size-md);
}

h6 {
  font-size: var(--font-size-sm);
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition-fast);
}

a:hover {
  color: var(--primary-dark);
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.text-center {
  text-align: center;
}

.section-title {
  margin-bottom: 2.5rem;
}

.section-title h2 {
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
  color: var(--primary-color);
}

.section-title h2:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 50px;
  height: 3px;
  background: var(--secondary-color);
  transform: translateX(-50%);
}

/* Buttons */
.btn {
  display: inline-block;
  font-weight: var(--font-weight-medium);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: var(--border-radius-md);
  transition: var(--transition-normal);
  font-size: var(--font-size-md);
  line-height: 1.5;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  color: var(--white);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--white);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  color: var(--white);
}

.btn-outline {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-outline:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-lg {
  padding: 0.75rem 2rem;
  font-size: var(--font-size-lg);
}

.btn-sm {
  padding: 0.25rem 1rem;
  font-size: var(--font-size-sm);
}

/* Responsive Utilities */
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }

  h1 {
    font-size: calc(var(--font-size-xxxl) * 0.8);
  }

  h2 {
    font-size: calc(var(--font-size-xxl) * 0.8);
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }

  h1 {
    font-size: calc(var(--font-size-xxxl) * 0.7);
  }

  h2 {
    font-size: calc(var(--font-size-xxl) * 0.7);
  }

  h3 {
    font-size: calc(var(--font-size-xl) * 0.8);
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 1rem;
  }

  .btn {
    padding: 0.4rem 1.2rem;
  }

  .btn-lg {
    padding: 0.6rem 1.8rem;
  }
}