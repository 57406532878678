.about-section {
  padding: 80px 0;
  background-color: var(--light-gray);
  position: relative;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(19, 106, 78, 0.03) 0%, rgba(193, 47, 47, 0.03) 100%);
  z-index: 0;
}

.about-section .container {
  position: relative;
  z-index: 1;
}

.section-subtitle {
  font-size: 1.2rem;
  color: var(--text-muted);
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.about-content {
  margin-bottom: 5rem;
}

.about-story {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 2.5rem;
  box-shadow: var(--shadow-md);
  margin-bottom: 3rem;
}

.about-story h3,
.about-mission h3,
.team-section h3,
.supporters-section h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.about-story h3::after,
.about-mission h3::after,
.team-section h3::after,
.supporters-section h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: var(--secondary-color);
}

.about-story p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  color: var(--text-dark);
}

.about-story p:last-child {
  margin-bottom: 0;
}

.mission-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 3rem;
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 2.5rem;
  box-shadow: var(--shadow-md);
  align-items: center;
}

.mission-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
  color: var(--text-dark);
}

.mission-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mission-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 1.05rem;
}

.list-icon {
  color: var(--primary-color);
  margin-right: 1rem;
  font-size: 1.2rem;
}

.mission-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-placeholder {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-primary);
  box-shadow: var(--shadow-lg);
}

.image-placeholder i {
  font-size: 80px;
  color: var(--white);
}

.team-section,
.supporters-section {
  margin-bottom: 5rem;
}

.team-description,
.supporters-description {
  text-align: center;
  font-size: 1.1rem;
  max-width: 700px;
  margin: 0 auto 3rem;
  color: var(--text-muted);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2.5rem;
}

.team-card {
  background-color: var(--white);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.member-image {
  height: 250px;
  overflow: hidden;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.team-card:hover .member-image img {
  transform: scale(1.05);
}

.member-details {
  padding: 1.5rem;
}

.member-details h4 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.member-role {
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.member-bio {
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: var(--text-dark);
}

.member-social {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--light-gray);
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.member-social:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.supporters-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.supporter-card {
  background-color: var(--white);
  border-radius: var(--border-radius-md);
  padding: 1.5rem;
  box-shadow: var(--shadow-sm);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.supporter-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.supporter-card img {
  max-width: 120px;
  height: 80px;
  object-fit: contain;
}

.supporter-card span {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text-muted);
}

@media (max-width: 992px) {
  .mission-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .mission-image {
    order: -1;
  }

  .image-placeholder {
    width: 150px;
    height: 150px;
  }

  .image-placeholder i {
    font-size: 60px;
  }

  .supporters-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 60px 0;
  }

  .section-subtitle {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .about-story,
  .mission-container {
    padding: 1.5rem;
  }

  .about-story h3,
  .about-mission h3,
  .team-section h3,
  .supporters-section h3 {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  .about-story p,
  .mission-content p {
    font-size: 1rem;
  }

  .team-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }

  .supporters-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 576px) {

  .team-grid,
  .supporters-grid {
    grid-template-columns: 1fr;
    max-width: 320px;
    margin: 0 auto;
  }

  .member-image {
    height: 200px;
  }
}