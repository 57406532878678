@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Space+Grotesk:wght@400;500;600;700&display=swap');

:root {
  /* Primary Colors - Kausal Brand */
  --primary-dark: #1a5158;
  --primary-medium: #2a6d75;
  --primary-light: #3a8a91;
  --accent-mint: #a5cdbd;

  /* Accent Colors - Red Palette */
  --secondary-color: #c12f2f;
  --secondary-color-rgb: 193, 47, 47;
  --secondary-color-light: #d44f4f;
  --secondary-color-dark: #982525;

  /* Text Colors */
  --text-dark: #1f2937;
  --text-medium: #4b5563;
  --text-light: #ffffff;

  /* Background Colors */
  --dark-background: #1f2937;
  --light-background: #f3f4f6;
  --mint-background: #d9edf0;
  --white: #ffffff;

  /* Gradients */
  --gradient-primary: linear-gradient(to bottom right, var(--primary-dark), var(--primary-medium));
  --gradient-header: linear-gradient(135deg, #1a5158 0%, #2a6d75 50%, #3a8a91 100%);
  --gradient-secondary: linear-gradient(135deg, var(--secondary-color) 0%, var(--secondary-color-light) 100%);

  /* Typography */
  --font-primary: 'Space Grotesk', 'Inter', sans-serif;
  --font-secondary: 'Inter', 'Helvetica', sans-serif;

  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;

  /* Shadows */
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0 20px 25px rgba(0, 0, 0, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-dark);
  background-color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  line-height: 1.2;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary-medium);
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--primary-dark);
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: var(--border-radius-md);
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: var(--font-primary);
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-primary {
  background: var(--gradient-secondary);
  color: var(--text-light);
}

.btn-primary:hover:not(:disabled) {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid var(--primary-medium);
  color: var(--primary-medium);
}

.btn-secondary:hover:not(:disabled) {
  background-color: var(--primary-medium);
  color: var(--text-light);
  transform: translateY(-3px);
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--primary-dark);
  font-family: var(--font-primary);
}

.section-subtitle {
  font-size: 1.2rem;
  color: var(--text-medium);
  margin-bottom: 2rem;
  max-width: 700px;
}

.text-center {
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.page-container {
  padding-top: 80px;
  /* Account for fixed header */
  min-height: calc(100vh - 80px);
}

/* Page transitions */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

@media (max-width: 992px) {
  .section-title {
    font-size: 2.2rem;
  }

  .section-subtitle {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.8rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .btn {
    padding: 0.625rem 1.25rem;
  }
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation utilities */
.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.slide-up {
  animation: slideUp 0.8s ease-in-out;
}

.slide-in-left {
  animation: slideInLeft 0.8s ease-in-out;
}

.slide-in-right {
  animation: slideInRight 0.8s ease-in-out;
}

/* Utilities */
.text-gradient {
  background: linear-gradient(to right, var(--accent-mint), #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

@media (max-width: 992px) {
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {

  .grid-3,
  .grid-2 {
    grid-template-columns: 1fr;
  }
}

/* Utility classes */
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-white {
  color: var(--white);
}

.bg-light {
  background-color: var(--light-gray);
}

.bg-dark {
  background-color: var(--dark-gray);
  color: var(--text-light);
}

.bg-primary {
  background-color: var(--primary-color);
  color: var(--text-light);
}

.bg-secondary {
  background-color: var(--secondary-color);
  color: var(--text-light);
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: var(--spacing-xs);
}

.p-2 {
  padding: var(--spacing-sm);
}

.p-3 {
  padding: var(--spacing-md);
}

.p-4 {
  padding: var(--spacing-lg);
}

.p-5 {
  padding: var(--spacing-xl);
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: var(--spacing-xs);
}

.m-2 {
  margin: var(--spacing-sm);
}

.m-3 {
  margin: var(--spacing-md);
}

.m-4 {
  margin: var(--spacing-lg);
}

.m-5 {
  margin: var(--spacing-xl);
}

.rounded {
  border-radius: var(--border-radius-md);
}

.shadow {
  box-shadow: var(--shadow-md);
}

/* About Page Styles */
.about-page-header {
  background-color: var(--primary-dark);
  color: var(--text-light);
  padding: 120px 0 60px;
  text-align: center;
  position: relative;
}

.about-page-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(19, 106, 78, 0.7) 0%, rgba(14, 80, 58, 0.9) 100%);
  z-index: 0;
}

.about-page-header .container {
  position: relative;
  z-index: 1;
}

.about-page-header h1 {
  color: var(--white);
  margin-bottom: 1rem;
  font-size: 3rem;
}

.about-page-header .lead {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  max-width: 700px;
  margin: 0 auto;
}

/* Placeholder Page Styles */
.placeholder-page {
  padding-top: 80px;
}

.placeholder-container {
  padding: 120px 20px 60px;
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder-content {
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--light-gray);
  padding: 2rem;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
}

.placeholder-page h1 {
  margin-bottom: 2rem;
  color: var(--primary-dark);
}

.placeholder-page p {
  margin-bottom: 1.5rem;
  color: var(--text-medium);
}

/* Features Page Styles */
.features-page-header {
  background-color: var(--secondary-color);
  color: var(--text-light);
  padding: 120px 0 60px;
  text-align: center;
  position: relative;
}

.features-page-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(193, 47, 47, 0.7) 0%, rgba(158, 36, 36, 0.9) 100%);
  z-index: 0;
}

.features-page-header .container {
  position: relative;
  z-index: 1;
}

.features-page-header h1 {
  color: var(--white);
  margin-bottom: 1rem;
  font-size: 3rem;
}

.features-page-header .lead {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  max-width: 700px;
  margin: 0 auto;
}

/* Contact Page Styles */
.contact-page-header {
  background-color: var(--primary-medium);
  color: var(--text-light);
  padding: 120px 0 60px;
  text-align: center;
  position: relative;
}

.contact-page-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(42, 109, 117, 0.7) 0%, rgba(26, 81, 88, 0.9) 100%);
  z-index: 0;
}

.contact-page-header .container {
  position: relative;
  z-index: 1;
}

.contact-page-header h1 {
  color: var(--white);
  margin-bottom: 1rem;
  font-size: 3rem;
}

.contact-page-header .lead {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 768px) {

  .about-page-header h1,
  .features-page-header h1,
  .contact-page-header h1 {
    font-size: 2.5rem;
  }

  .about-page-header .lead,
  .features-page-header .lead,
  .contact-page-header .lead {
    font-size: 1.1rem;
  }

  .about-page-header,
  .features-page-header,
  .contact-page-header {
    padding: 100px 0 50px;
  }
}