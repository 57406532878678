.value-proposition {
  padding: 5rem 0;
  background-color: #016565;
  color: white;
}

.value-proposition .section-title {
  text-align: center;
  margin-bottom: 3rem;
}

.value-proposition .section-title h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.value-proposition .section-subtitle {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.85);
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.value-card {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  height: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}

.value-icon {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 1.5rem;
}

.value-icon i {
  font-size: 2rem;
  color: #E6F4F1;
}

.value-content {
  flex: 1;
}

.value-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
}

.value-card p {
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.6;
  font-size: 1.05rem;
}

@media (max-width: 992px) {
  .values-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .value-proposition {
    padding: 4rem 0;
  }

  .value-proposition .section-title h2 {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .values-grid {
    grid-template-columns: 1fr;
  }
}