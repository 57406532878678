.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 3rem;
  overflow: hidden;
  box-sizing: border-box;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9edf0;
  /* Light teal/mint background from the flyer */
  z-index: -1;
}

.hero-background::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  /* background: linear-gradient(to, var(--secondary-color-light) 0%, transparent 70%); */
  opacity: 0.4;
  z-index: 0;
}

/* Create a subtle fade for the right side - replacing the harsh red line */
.hero-background::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(217, 237, 240, 0) 0%,
      rgba(217, 237, 240, 0.2) 30%,
      rgba(220, 79, 79, 0.08) 80%,
      rgba(220, 79, 79, 0.12) 100%);
  z-index: 0;
  opacity: 0.7;
}

.hero-section .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  box-sizing: border-box;
}

.hero-content {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s ease;
}

.hero-section.animated .hero-content {
  opacity: 1;
  transform: translateY(0);
}

.hero-content h1 {
  font-size: clamp(2.2rem, 3.5vw, 3.5rem);
  font-weight: 700;
  color: #1a5158;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.text-accent {
  color: #2a6d75;
  font-weight: 700;
}

.hero-subtitle {
  font-size: clamp(1.1rem, 1.5vw, 1.3rem);
  color: #1a5158;
  margin-bottom: 2rem;
  max-width: 600px;
  line-height: 1.6;
  opacity: 0.9;
}

.hero-stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1.5rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--secondary-color);
  line-height: 1;
}

.stat-label {
  font-size: 1.1rem;
  color: #1a5158;
  margin-top: 0.5rem;
}

.stat-description {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1a5158;
  max-width: 300px;
  position: relative;
  padding-left: 1.5rem;
}

.stat-description::before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 1rem;
  height: 2px;
  background-color: var(--secondary-color);
}

.hero-actions {
  display: flex;
  gap: 1rem;
}

.hero-actions .btn-primary {
  background: var(--gradient-secondary);
}

.hero-actions .btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(193, 47, 47, 0.3);
}

.hero-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.8s ease 0.3s;
  position: relative;
  max-width: 100%;
  height: auto;
}

.hero-section.animated .hero-visual {
  opacity: 1;
  transform: scale(1);
}

.from-data-badge {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3a8a91;
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-section .container {
    grid-template-columns: 1fr;
    gap: 3rem;
    text-align: center;
  }

  .hero-content {
    order: 1;
  }

  .hero-visual {
    order: 0;
    margin: 0 auto;
    max-width: 80%;
  }

  .hero-subtitle {
    margin-left: auto;
    margin-right: auto;
  }

  .hero-stats {
    justify-content: center;
  }

  .stat-description {
    padding-left: 0;
  }

  .stat-description::before {
    display: none;
  }

  .hero-actions {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding-top: 6rem;
    padding-bottom: 3rem;
    min-height: auto;
  }

  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .hero-actions {
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    width: 100%;
  }

  .hero-actions .btn {
    width: 100%;
    max-width: 300px;
  }

  .hero-visual {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .hero-section {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  .hero-section .container {
    padding: 0 1rem;
    gap: 1.5rem;
  }

  .hero-content h1 {
    font-size: 1.8rem;
    margin-bottom: 0.75rem;
  }

  .hero-subtitle {
    font-size: 0.95rem;
    margin-bottom: 1.25rem;
  }

  .hero-visual {
    width: 100%;
    margin: 0 auto;
  }

  .hero-actions .btn {
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 1.25rem;
    font-size: 0.9rem;
  }

  .stat-number {
    font-size: 2rem;
  }

  .stat-label {
    font-size: 0.9rem;
  }

  .from-data-badge {
    font-size: 0.9rem;
    padding: 0.75rem 1.25rem;
    bottom: -10px;
    right: 10px;
  }
}