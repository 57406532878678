.news-page {
  padding: 120px 0 80px;
}

.news-header {
  text-align: center;
  margin-bottom: 3rem;
}

.news-header h1 {
  color: var(--primary-dark);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.news-content {
  max-width: 900px;
  margin: 0 auto;
}

.news-items {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.news-item {
  background-color: var(--bg-light);
  border-radius: var(--border-radius-lg);
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  position: relative;
  overflow: hidden;
  border-left: 4px solid var(--primary-medium);
}

.news-item-date {
  font-size: 0.9rem;
  color: var(--text-medium);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.news-item-title {
  font-size: 1.8rem;
  color: var(--primary-dark);
  margin-bottom: 1rem;
  line-height: 1.3;
}

.news-item-image {
  margin: 1rem 0;
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.news-item-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-item-image img:hover {
  transform: scale(1.03);
}

.news-item-summary {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.news-item-content {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-medium);
}

.news-item-content p {
  margin-bottom: 1rem;
}

/* Links section styling */
.news-item-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.news-item-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-sm);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

/* Primary link styling */
.news-item-link.primary {
  background-color: var(--primary-medium);
  color: var(--white);
}

.news-item-link.primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

/* Secondary link styling */
.news-item-link.secondary {
  background-color: var(--white);
  color: var(--primary-medium);
  border: 1px solid var(--primary-medium);
}

.news-item-link.secondary:hover {
  background-color: var(--primary-light);
  color: var(--primary-dark);
  transform: translateY(-2px);
}

/* Legacy link styling (for backward compatibility) */
.news-item-link:not(.primary):not(.secondary) {
  background-color: var(--primary-medium);
  color: var(--white);
}

.news-item-link:not(.primary):not(.secondary):hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

.no-news {
  text-align: center;
  padding: 3rem;
  font-size: 1.2rem;
  color: var(--text-medium);
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .news-page {
    padding: 100px 0 60px;
  }

  .news-header h1 {
    font-size: 2rem;
  }

  .news-item {
    padding: 1.5rem;
  }

  .news-item-title {
    font-size: 1.5rem;
  }

  .news-item-summary {
    font-size: 1rem;
  }

  .news-item-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .news-item-link {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .news-page {
    padding: 80px 0 40px;
  }

  .news-header h1 {
    font-size: 1.75rem;
  }

  .news-item {
    padding: 1.25rem;
  }

  .news-item-title {
    font-size: 1.3rem;
  }
}