/* Add this at the top level to prevent horizontal scrolling */
html,
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: var(--transition-normal);
  background-color: transparent;
  padding: 1rem 0;
  height: 60px;
  box-sizing: border-box;
  max-width: 100vw;
}

.header.scrolled {
  background-color: var(--white);
  box-shadow: var(--shadow-sm);
  padding: 0.5rem 0;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  box-sizing: border-box;
  overflow-x: visible;
}

.logo {
  flex: 0 0 auto;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img {
  height: 40px;
  width: auto;
  transition: var(--transition-normal);
}

.logo-text {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-left: 0.5rem;
  transition: var(--transition-normal);
}

.header.scrolled .logo img {
  height: 35px;
}

.header.scrolled .logo-text {
  font-size: 1.3rem;
}

.main-nav {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.nav-links a {
  color: var(--text-dark);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  transition: var(--transition-normal);
  position: relative;
  padding: 0.5rem 0;
}

.nav-links a:hover {
  color: var(--primary-color);
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: var(--transition-normal);
}

.nav-links a:hover::after {
  width: 100%;
}

.header-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Hide the Schedule Demo button on all views */
.header-actions .btn {
  display: none;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 0.5rem;
  z-index: 1001;
}

.hamburger {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  background-color: var(--primary-dark);
  transition: var(--transition-normal);
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--primary-dark);
  transition: var(--transition-normal);
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

.hamburger.open {
  background-color: transparent;
}

.hamburger.open::before {
  transform: rotate(45deg);
  top: 0;
}

.hamburger.open::after {
  transform: rotate(-45deg);
  bottom: 0;
}

@media (max-width: 992px) {
  .header {
    background-color: rgba(255, 255, 255, 0.97);
  }

  .header-content {
    padding: 0 1.5rem;
  }

  .mobile-menu-toggle {
    display: block;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2000;
  }

  .main-nav {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.97);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
  }

  .main-nav.mobile-open {
    height: calc(100vh - 80px);
    padding: 1.5rem 0;
    overflow-y: auto;
  }

  body.menu-open {
    overflow: hidden;
  }

  .nav-links {
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;
    align-items: center;
    width: 100%;
  }

  .nav-links a {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
  }

  .header-actions {
    margin-left: auto;
    position: relative;
    z-index: 1002;
    flex-shrink: 0;
  }
}

@media (max-width: 576px) {
  .header {
    height: 70px;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
  }

  .header-content {
    padding: 0 0.75rem;
    width: 100%;
    box-sizing: border-box;
  }

  .logo img {
    height: 32px;
  }

  .logo-text {
    font-size: 1.2rem;
  }

  .main-nav {
    top: 70px;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
  }

  .main-nav.mobile-open {
    height: calc(100vh - 70px);
    width: 100%;
  }

  .mobile-menu-toggle {
    right: 0.75rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 8px;
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    background-color: var(--primary-dark);
    height: 3px;
  }
}

@media (max-width: 414px) {
  .header {
    height: 60px;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow-x: visible;
  }

  .header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .logo {
    max-width: 70%;
    margin-right: auto;
  }

  .logo img {
    height: 28px;
  }

  .header-actions {
    position: static;
    display: flex;
    justify-content: flex-end;
    width: auto;
  }

  .main-nav {
    top: 60px;
    width: 100%;
    left: 0;
  }

  .main-nav.mobile-open {
    height: calc(100vh - 60px);
    width: 100%;
  }

  .mobile-menu-toggle {
    right: 15px;
    padding: 6px 8px;
    display: block;
    position: absolute;
    z-index: 2000;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    margin-left: 10px;
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    width: 22px;
  }
}