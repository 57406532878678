.footer {
  background-color: var(--primary-dark);
  padding: 4rem 0 2rem;
  color: var(--text-light);
  position: relative;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(19, 106, 78, 0.7) 0%, rgba(14, 80, 58, 0.9) 100%);
  z-index: 0;
}

.footer .container {
  position: relative;
  z-index: 1;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 6px 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer-logo {
  height: 36px;
  width: auto;
  margin-right: 12px;
  filter: none;
}

.footer-logo-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-dark);
  letter-spacing: 0.5px;
}

.footer-tagline {
  font-size: 0.95rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
  max-width: 300px;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
  transition: all 0.3s ease;
}

.footer-social a:hover {
  background-color: var(--secondary-color);
  color: var(--white);
  transform: translateY(-3px);
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.footer-links-column h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: var(--white);
}

.footer-links-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links-column li {
  margin-bottom: 0.8rem;
}

.footer-links-column a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.footer-links-column a:hover {
  color: var(--white);
  padding-left: 5px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 992px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-brand {
    align-items: center;
    text-align: center;
  }

  .footer-tagline {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-links {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 3rem 0 1.5rem;
  }

  .footer-links {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .footer-links-column {
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
}