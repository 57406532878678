.causal-graph-container {
  position: relative;
  width: 100%;
  height: 500px;
  max-width: 600px;
  margin: 0 auto;
}

.causal-graph-canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.graph-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.root-cause-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  background-color: rgba(42, 109, 117, 0.8);
  color: var(--text-light);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -80%) scale(1);
    opacity: 0.9;
  }

  50% {
    transform: translate(-50%, -80%) scale(1.05);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -80%) scale(1);
    opacity: 0.9;
  }
}

@media (max-width: 768px) {
  .causal-graph-container {
    height: 400px;
  }
}

@media (max-width: 480px) {
  .causal-graph-container {
    height: 300px;
  }
}